<template>
  <CPageSection>
    <CTypestyleHeadline>
      <h3 class="">Unverbindliche <CTypestyleBoldstrong>Reiseanfrage</CTypestyleBoldstrong>
      </h3>
    </CTypestyleHeadline>
    <p class="mb-7">Du suchst das perfekte Hotel für deinen nächsten Urlaub? Wir helfen dir gerne dabei, die passende Unterkunft zu finden. Erzähl uns einfach deine Wünsche und Vorstellungen - ganz unverbindlich. Fülle dazu unser kurzes Formular aus und wir senden dir schnellstmöglich maßgeschneiderte Angebote!!!</p>

    <form action="https://submit-form.com/ADcqvGIjP" class="grid gap-8">
      <input type="hidden" name="_redirect" value="https://www.costeri.de/" />
      <input type="hidden" name="_append" value="false" />
      <input type="hidden" name="_email.from" value="Costéri Website - Reiseanfrage" />
      <input type="hidden" name="_email.subject" value="Meine Reiseanfrage" />
      <input type="hidden" name="_email.template.title" value="Reiseanfrage" />

      <div class="grid sm:grid-cols-2 gap-8 input-icons">
        <div class="flex flex-col">
          <label for="tripType" class="font-bold">Reiseart (Pauschal, nur Hotel, Kreuzfahrt, ...)*</label>
          <input type="text" id="tripType" name="trip1_Type" placeholder="&#9732; z.B. Rundreise USA Pauschal" required />
        </div>
        <div class="flex flex-col">
          <label for="tripDestination" class="font-bold">Reiseziele*</label>
          <input type="text" id="tripDestination" name="trip2_Destination" placeholder="&#10084; z.B. Italien, Dubai, Mexiko" required />
        </div>
      </div>

      <div class="grid sm:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="tripAirport" class="font-bold">Flughäfen (Abflug)</label>
          <input type="text" id="tripAirport" name="trip3_Airport" placeholder="&#9992; z.B. Frankfurt, Stuttgart, MUC, BER" />
        </div>
        <div class="flex flex-col">
          <label for="tripDuration" class="font-bold">Reisedauer*</label>
          <input type="text" id="tripDuration" name="trip4_Duration" placeholder="z.B. 7-9 Nächte" />
        </div>
      </div>

      <div class="grid sm:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="tripPeriodEarliest" class="font-bold">Frühestes Abreisedatum*</label>
          <input type="date" id="tripPeriodEarliest" name="trip5_PeriodEarliest" :min="earliestDepartureDate" required />
        </div>
        <div class="flex flex-col">
          <label for="tripPeriodLatest" class="font-bold">Spätestes Abreisedatum*</label>
          <input type="date" id="tripPeriodLatest" name="trip6_PeriodLatest" :min="latestDepartureDate" required />
        </div>
      </div>

      <div class="grid sm:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="tripFood" class="font-bold">Verpflegung*</label>
          <input type="text" id="tripFood" name="trip7_Food" placeholder="z.B. nur Frühstück, All-Inclusive" />
        </div>
        <div class="flex flex-col">
          <label for="tripBudget" class="font-bold">Gesamtbudget € (Erwachsene inkl. Kinder)*</label>
          <input type="number" id="tripBudget" name="trip8_Budget" placeholder="2850" required />
        </div>
      </div>

      <div class="flex flex-col">
        <label for="personMessage" class="font-bold">Deine Wünsche</label>
        <textarea id="personMessage" name="person5_Message" class="min-h-40" placeholder="z.B. Meerblick, Rollstuhl barrierefrei" required></textarea>
      </div>

      <div class="grid sm:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="personFirstname" class="font-bold">Vorname*</label>
          <input type="text" id="personFirstname" name="person1_Firstname" placeholder="Vorname" required />
        </div>
        <div class="flex flex-col">
          <label for="personLastname" class="font-bold">Nachname*</label>
          <input type="text" id="personLastname" name="person2_Lastname" placeholder="Nachname" required />
        </div>
      </div>

      <div class="grid sm:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="personEmail" class="font-bold">E-Mail Adresse*</label>
          <input type="email" id="personEmail" name="person3_Email" placeholder="&#9993; vorname.nachname@costeri.de" required />
        </div>
        <div class="flex flex-col">
          <label for="personPhone" class="font-bold">Telefonnummer*</label>
          <input type="text" id="personPhone" name="person4_Phone" placeholder="&phone; 0151 123 4567" required />
        </div>
      </div>

      <div class="relative flex max-sm:flex-col cos-links-std">
        <input type="checkbox" id="zDataPrivacy" name="z_DataPrivacy" required class="mr-3" />
        <label for="zDataPrivacy" class="max-sm:mt-3">Ich habe die <NuxtLink href="/datenschutz" target="_blank">Datenschutzerklärung</NuxtLink> gelesen und akzeptiert.</label>
      </div>

      <button type="submit" class="py-1.5 px-6 bg-sand-400 font-semibold text-white hover:bg-sand-400 hover:text-white max-w-max">Senden</button>
    </form>
  </CPageSection>
</template>

<script setup lang="ts">
const SHORTEST_TAKEOFF_DAYS = 4
const LONGEST_TAKEOFF_DAYS = 4
let earliestDepartureDate = ref(new Date().toISOString().split('T')[0])
let latestDepartureDate = ref(new Date().toISOString().split('T')[0])

onMounted(() => {
  const currentDate = new Date();
  const takeoffDate1 = new Date(currentDate);
  const takeoffDate2 = new Date(currentDate);
  takeoffDate1.setDate(currentDate.getDate() + SHORTEST_TAKEOFF_DAYS);
  takeoffDate2.setDate(currentDate.getDate() + LONGEST_TAKEOFF_DAYS);
  earliestDepartureDate.value = takeoffDate1.toISOString().split('T')[0]
  latestDepartureDate.value = takeoffDate2.toISOString().split('T')[0]
})
</script>

<style lang="css">
textarea,
textarea:hover,
textarea:active,
textarea:visited,
textarea:focus,
input:not([type="checkbox"]):hover,
input:not([type="checkbox"]):active,
input:not([type="checkbox"]):focus,
input:not([type="checkbox"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 1px solid #c0af9f;
  /* background: #fdfbfa; */
  transition: all 0.4s;
  padding: 8px 10px;
  margin: 4px 0;
  outline: transparent;
}

/*
    remove the default styling of the checkbox using appearance
    and create the outer toggle area
*/

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 48px;
  height: 24px;
  min-width: 48px;
  min-height: 24px;
  border-radius: 12px;
  border: 2px solid black;
  background: #eee;
  transition: all 0.4s;
}

/*
    style the ::before content to look like
    the inner toggle switch - the bit that moves
*/

input[type="checkbox"]::before {
  width: 16px;
  height: 16px;
  border-radius: 9px;
  background-color: black;
  content: '';
  position: absolute;
  top: 4px;
  left: 6px;
  transition: all 0.4s;
}

/*
    change the outer background color and move the inner toggle switch
    when the checkbox is checked; use transitions for smooth animation
*/

input[type="checkbox"]:checked {
  background-color: #bca791;
  transition: all 0.4s;
}

input[type="checkbox"]:checked::before {
  left: 26px;
  transition: all 0.4s;
}

/*
    make the focus outline a bit more subtle on Chrome, rather than the
    default heavy blue outline
*/

input:focus {
  outline: 1px dotted black;
}


.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.input-icons i {
  position: absolute;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}
</style>
